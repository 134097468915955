<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="plantingTypes"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            PlantingTypes
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            width="800"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Planting Type
              </v-btn>
            </template>
            <PlantingTypeForm
              @created="handleCreatedPlantingType"
              @canceled="handleCanceledPlantingType"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/plantingTypes/${item._id}`"
          @delete="deletePlantingType(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'
import { plantingTypeFields } from './constants'
import PlantingTypeForm from './PlantingTypeForm'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminPlantingType',
  components: {
    PlantingTypeForm,
    EditAndDeleteButtons
  },
  data () {
    const plantingTypeColumns = plantingTypeFields.map(({ name, label, suffix }) => {
      const text = label + (suffix ? ` (${suffix})` : '')
      return {
        text,
        value: name
      }
    })
    const headers = [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'CPD - PlantingType Id',
        value: 'cpdPlantingTypeId'
      },
      ...plantingTypeColumns,
      {
        text: 'Notes',
        value: 'notes'
      },
      {
        text: 'Citations',
        value: 'citations'
      },
      {
        value: 'actions',
        sortable: false
      }
    ]
    return {
      headers,
      plantingTypes: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchPlantingTypes()
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchPlantingTypes () {
      const { data: plantingTypes } = await this.$axios.get('/planting_types')
      this.plantingTypes = plantingTypes
    },
    async deletePlantingType (id) {
      try {
        await this.$axios.delete(`/planting_types/${id}`)
        await this.fetchPlantingTypes()
      } catch (err) {
        await this.showSnackbar({ color: 'error', message: 'Unable to delete plantingType. Please try again.' })
        throw err
      }
    },
    handleCanceledPlantingType () {
      this.showForm = false
    },
    handleCreatedPlantingType () {
      this.showForm = false
      this.fetchPlantingTypes()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
